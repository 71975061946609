<template>
  <div class="page" id="page">
    <div class="mine">
      <div class="top" style="margin-top: 80px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">社区自媒体</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">社区生活视频分享</p>
      </div>

      <div class="content">
        <div class="p-box">
          <el-image
            style="width: 220px; height: 440px"
            :src="require('../assets/platformp/user-1.jpg')"
          ></el-image>

          <p class="title-d">品类繁多</p>
          <p class="d-t">
            视频类型多样，服务类型多样
          </p>
        </div>
        <div class="p-box">
          <el-image
            style="width: 220px; height: 440px"
            :src="require('../assets/platformp/user-2.jpg')"
          ></el-image>
          <p class="title-d">积分抵扣物业费</p>
          <p class="d-t">
            积极缴纳物业费可获得积分，积分可抵扣物业费
          </p>
        </div>
        <div class="p-box">
          <el-image
            style="width: 220px; height: 440px"
            :src="require('../assets/platformp/user-3.jpg')"
          ></el-image>
          <p class="title-d">独特新品</p>
          <p class="d-t">
            社区可卖各种生活服务用品
          </p>
        </div>
      </div>

      <div class="top" style="margin-top: 180px; width: 520px">
        <div class="title">
          <div class="lent"></div>
          <div class="title-text">未来+深度覆盖800+小区</div>
          <div class="lent"></div>
        </div>
        <p class="title-bottom-font">
          板块自由组合，专属于您的指挥物业方案 拷贝
        </p>
      </div>

      <div class="bottom-img">
        <div>
          <el-image
            style="width: 290px; height: 150px"
            :src="require('../assets/platformp/home.png')"
          ></el-image>
          <p>蓝竹湾公寓</p>
        </div>
        <div>
          <el-image
            style="width: 290px; height: 150px"
            :src="require('../assets/platformp/home1.png')"
          ></el-image>
          <p>白金公寓</p>
        </div>
        <div>
          <el-image
            style="width: 290px; height: 150px"
            :src="require('../assets/platformp/home2.png')"
          ></el-image>
          <p>红星嘉园小区</p>
        </div>
        <div>
          <el-image
            style="width: 290px; height: 150px"
            :src="require('../assets/platformp/home3.png')"
          ></el-image>
          <p>金桂嘉园小区</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
  watch: {},
};
</script>
<style scoped lang="less">
.page {
  width: 100%;
  text-align: center;
  .mine {
    width: 65%;
    margin: auto;
  }

  .top {
    width: 300px;
    margin: auto;
    .title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .lent {
        width: 48px;
        height: 2px;
        background-color: #333333;
        // border: 1px solid #333333;
      }
      .title-text {
        font-weight: bold;
        color: #333333;
        font-size: 32px;
      }
    }
    .title-bottom-font {
      font-weight: 400;
      color: #999999;
      font-size: 16px;
    }
  }
  .content {
    margin: 100px 0 200px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title-d {
      margin-top: 100px;
      height: 22px;
      font-size: 22px;
      font-weight: bold;
      color: #333333;
    }
    .d-t {
      text-align: center;
      width: 372px;
      font-size: 20px;
      font-weight: 400;
      color: #999999;
      line-height: 27px;
    }
  }
  .bottom-img {
    margin: 50px 0 200px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
